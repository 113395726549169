#Navbar_Admin_PowerBi_Body {
    background-color: white;
    display: flex;
}


@media (max-width: 2000px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 55vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 27%;
    }
}

@media (max-width: 1500px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 50vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 24%;
    }

}

@media (max-width: 1200px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 45vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 20%;
    }

    .custom-button {
        left: 5%;
    }

    #Navbar_Admin_PowerBi_Top {
        left: 8%;
    }


}

@media (max-width: 1024px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 40vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 17%;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        top: 0;
    }

    #Section_Filters_Admin_PowerBi {
        margin-top: 12%;
    }

}

@media (max-width: 900px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 35vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 14%;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        top: 0;
    }
}

@media (max-width: 800px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 30vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 11%;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        top: 0;
    }

    #Navbar_Admin_PowerBi_Top {
        left: 0;
    }
}

@media (max-width: 650px) {
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 25vw;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 7%;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        top: 0;
    }

    #Div_Content_Right_Admin_PowerBi {
        width: 80%;
    }
}


/*Section Filter AdminVideos*/
#Section_Filters_Admin_PowerBi {
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    place-content: center;
    margin-top: 3%;
    margin-bottom: 1%;
}

#Buttons_Filter_Admin_PowerBi {
    margin: 2px 20px;
    background-color: transparent;
    color: black;
    border-radius: 20px;
}

#Buttons_Filter_Admin_PowerBi:hover {
    background-color: #2A364E;
    box-shadow: black 1px 1px 5px 1px;
    color: white;
}


/*Section Right AdminVideos*/

#Div_Content_Right_Admin_PowerBi {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 90vw;
    height: 92%;
    left: 7%;
    top: 10%;
}

#Table_Admin_PowerBi {
    width: 90vw;
    height: 100%;
}

#Table_Admin_PowerBi > thead > tr:nth-child(1) {
    position: sticky;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    height: 10vh;
    top: 10%;
    left: 0;
    z-index: 9;
}

#TR_Admin_PowerBi_Virtual_Library {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    border-radius: 20px;
    margin: 1%;
    padding: 1%;
}

#TR_Admin_PowerBi_Virtual_Library:hover {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    transition: box-shadow 0.3s ease;
}


#Div_Content_Right_Admin_PowerBi_Table {
    flex-direction: column;
    display: flex;
    flex-wrap: wrap;

}

.bauble_box .bauble_label {
    background: #333333 no-repeat 62px 5px;
    background-size: auto 5px;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    font-size: 0;
    height: 30px;
    position: relative;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    width: 55px;
    bottom: 10px;

}

/*switch of the table*/
#bauble_check1 {
    display: flex;
    position: absolute;
    height: 40px;
    width: 50px;
    z-index: 2;
    margin: 0;
    padding: 0;
    justify-content: center;
    cursor: pointer;
}

#bauble_box #bauble_label:before {
    background-color: rgba(255, 255, 255, .2);
    background-position: 0 0;
    background-repeat: repeat;
    background-size: 30% auto;
    border-radius: 50%;
    box-shadow: inset 0 -5px 25px #686464, 0 10px 20px rgba(0, 0, 0, .4);
    content: '';
    display: block;
    height: 20px;
    left: 5px;
    position: absolute;
    top: 6px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    width: 20px;
    z-index: 1;
}

#bauble_box #bauble_check1.bauble_input {
    opacity: 0;
}

/* Checked */
#bauble_box #bauble_check1.bauble_input:checked + #bauble_label {
    background-color: #DB692F;
}

#bauble_box #bauble_check1.bauble_input:checked + #bauble_label:before {
    background-position: 150% 0;
    box-shadow: inset 0 -5px 25px #f36926, 0 10px 20px rgba(0, 0, 0, 0.93);
    left: calc(100% - 25px);
}


/*Button Edit*/

#EditBtn {
    width: 45px;
    height: 45px;
    border-radius: 20px;
    border: none;
    background-color: #DB692F;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.123);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
}

#EditBtn::before {
    content: "";
    width: 200%;
    height: 200%;
    background-color: #283542;
    position: absolute;
    z-index: 1;
    transform: scale(0);
    transition: all 0.3s;
    border-radius: 50%;
    filter: blur(10px);
}

#EditBtn:hover::before {
    transform: scale(1);
}

#EditBtn:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.336);
}

#EditBtn svg {
    height: 17px;
    fill: white;
    z-index: 3;
    transition: all 0.2s;
    transform-origin: bottom;
}

#EditBtn:hover svg {
    transform: rotate(-15deg) translateX(5px);
}

#EditBtn::after {
    content: "";
    width: 25px;
    height: 1.5px;
    position: absolute;
    bottom: 10px;
    left: -5px;
    background-color: white;
    border-radius: 2px;
    z-index: 2;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-out;
}

#EditBtn:hover::after {
    transform: scaleX(1);
    left: 0;
    transform-origin: right;
}


#Div_Edit_Body_Admin_PowerBi {
    z-index: 3;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

}

#Section_Edit_Admin_PowerBi {
    position: absolute;
    width: 50%;
    height: 80%;
    background-color: white;
    top: 10%;
    left: 25%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.123);
}

#H1_Section_Edit_Admin_PowerBi {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin: 2%;
    font-family: 'Lato', sans-serif;
}

#Form_Div_Edit_Admin_PowerBi {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}


.label-edit-admin {
    display: block;
    margin-bottom: 8px;
    color: #333;
}

.input-edit-admin {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 16px;
    transition: border-color 0.3s ease;
}

.input-edit-admin:focus {
    border-color: #007bff;
}

select.input-edit-admin {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 16px;
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 8px center/16px 16px;
}

.select-edit-admin:focus {
    border-color: #007bff;
}


#Button_Submit_Edit_Admin_PowerBi,
#Button_Cancel_Edit_Admin_PowerBi {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#Button_Submit_Edit_Admin_PowerBi {
    position: relative;
    right: 20px;
    background-color: #DB692F;
    color: #fff;
}

#Button_Cancel_Edit_Admin_PowerBi {
    position: relative;
    left: 20px;
    background-color: #686464;
    color: #fff;
}

#Button_Submit_Edit_Admin_PowerBi:hover {
    background-color: #2A364E;
}

#Button_Cancel_Edit_Admin_PowerBi:hover {
    background-color: #424141;
}

#Div_Form_Buttons_Edit_Admin_PowerBi {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2%;
}

.custom-button {
    display: flex;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s;
    z-index: 3;
    position: absolute;
    margin: 1% 1%;
}

.custom-button:hover {
    transform: rotate(90deg);
}

.custom-svg {
    stroke: #DB692F; /* Cambia el color de la línea del SVG aquí */
    fill: none;
    transition: fill 0.3s, stroke 0.3s;
}

.custom-button:hover .custom-svg {
    fill: #DB692F; /* Cambia el color del relleno del SVG al pasar el ratón por encima */
    stroke: #2A364E; /* Cambia el color de la línea del SVG al pasar el ratón por encima */
    z-index: 3;
}

.custom-button:active .custom-svg {
    fill: #f9d9c5; /* Cambia el color del relleno del SVG cuando está activo */
    stroke: #DB692F; /* Cambia el color de la línea del SVG cuando está activo */
    transition-duration: 0s; /* Elimina la transición al hacer clic para que el cambio sea instantáneo */
}

#Id_Text_Area_Add_Video_Admin_PowerBi {
    position: relative;
    border: 2px solid #DB692F;
    border-radius: 5px;
    width: 200%;
    height: 100px;
    overflow: scroll;
    right: 50%;
}

#Id_Text_Area_Edit_Video_Admin_PowerBi {
    position: relative;
    border: 2px solid #DB692F;
    border-radius: 5px;
    width: 200%;
    height: 100px;
    overflow: scroll;
    right: 50%;
}
