#Section_Video_Library {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 90%;
    width: 90%;
    position: absolute;
    left: 8%;
    top: 12vh;
    content: normal;
    padding: 0;
}

#Navbar_Admin_PowerBi_Top {
    background-color: white;
    min-height: 90px;
}
#Div_Navbar_Top_Admin_Profile_Icon {
    height: 60%;
    width: 60px;
    border-radius: 10pc;
    margin: 1% 2%;
    background: white url("../Statics/Img/IMG_Example_Profile.jpg") no-repeat center center / cover;
}

.card {
    display: flex;
    flex-direction: column;
    height: 40%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.08),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
    transition: all 0.3s;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
    position: relative;
    justify-content: center;
    align-items: center;
    width: calc(25% - 20px); /* Ajustamos el ancho de las tarjetas para que haya 4 tarjetas por fila */
    margin-right: 20px; /* Cambiamos margin-bottom por margin-right para evitar problemas con el ancho */
    margin-bottom: 20px;
    overflow: hidden; /* Evitamos el desbordamiento horizontal */
    left: 0.5%;
}

/* Para la última tarjeta en cada fila, eliminamos el margen derecho */
.card:last-child {
    margin-right: 0;
}


.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
}

.card-image-container {
    width: 100%;
    height: 64%;
    border-radius: 10px;
    margin-bottom: 12px;
    overflow: hidden;
    background: url("../Statics/Img/PowerBi/Dash2.png") no-repeat center center;
    display: flex;
    align-items: center;
    justify-content: center;
}


.card-title {
    margin: 0;
    font-size: 17px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-weight: 600;
    color: #000000;
    cursor: default;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
}

.card-des {
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin: 0;
    font-size: 13px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    color: #000000;
    cursor: default;
}

/* Path: tcce_co/src/Styles/VirtualLibraryVideoMenu.css */
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Para que las tarjetas se distribuyan uniformemente */
}


@media (max-width: 1080px) {
    #Section_Navbar_Top_Admin_PowerBi_Profile {
        justify-content: center;
        align-items: center;
    }

    #Section_Video_Library > div {
        font-size: 0.5em;
    }
}


@media (max-width: 800px) {
    #Section_Video_Library {
        left: 1%;
        width: 95%;
        top: 25vh;
    }

    .card {
        left: 3%;
        width: calc(50% - 20px) ; /* Ajustamos el ancho de las tarjetas para que haya 2 tarjetas por fila */
    }

    #Navbar_Admin_PowerBi_Top {
        top: 11vh;
    }
}

@media (max-width: 600px) {
    /* form style */
    #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 0;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 42%;
        position: absolute;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        position: absolute;
        left: 60%;
        width: 30%;
    }
}

@media (max-width: 500px) {
   #Form_Navbar_Top_Admin_PowerBi_Search {
        left: 0;
       width: 30%;
    }

    #Section_Navbar_Top_Admin_PowerBi_Widgets {
        left: 33%;
        position: absolute;
    }

    #Section_Navbar_Top_Admin_PowerBi_Profile {
        position: absolute;
        left: 55%;
        width: 40%;
    }
}
