/*THIS IS THE BODY OF THE COMPONENT LOGIN*/
/*----------------- Styles for the main body ---------------------------*/

#Body_Login {
    background-color: #f2f2f2; /* Background color */
    margin: 0; /* Margin */
    padding: 0; /* Padding */
    box-sizing: border-box; /* Box sizing */
    font-family: 'Lato', sans-serif; /* Font family */
    display: flex; /* Flex container */
    height: 100vh; /* Full viewport height */

}

/* Styles for labels */
#Label_Login,#Label_Password {
    font-family: "Inter", sans-serif; /* Font family */
    color: white; /* Text color */
    font-size: 1.5rem; /* Font size */
    margin-top: 20px; /* Margin top */
    background: transparent;
}


/* Styles for input groups */
 .input-group{
    background-color: white; /* Background color */
    margin-top: 10px; /* Margin top */
    height: 60px; /* Height */
    padding: 10px; /* Padding */
    border-radius: 10px; /* Border radius */
}


/* Styles for icons */
#I_Login {
    color: black; /* Icon color */
    font-size: 1.5rem; /* Icon size */
    margin: 10px; /* Margin */
}


/* Styles for input fields */
.form-control {
    background-color: white; /* Background color */
    border: none; /* Border */
    display: flex; /* Flex display */
    justify-content: end; /* Align contents horizontally */
    text-align: end; /* Align text to right */
}

/* Styles for input placeholders */
.form-control::placeholder {
    color: black; /* Placeholder color */
    font-size: 1.5rem; /* Placeholder font size */
    font-family: "Inter", sans-serif; /* Placeholder font family */
}

/* Styles for focused and active input fields */
.form-control:focus,
.form-control:active {
    outline: none; /* Remove outline */
}

/* Styles for active input fields */
.form-control:active {
    background-color: transparent; /* Transparent background */
    box-shadow: rgba(0, 0, 0, 0.65); /* Box shadow */
}


/* Styles for forms */
#Form_Login {
    display: grid; /* Grid display */
    grid-template-columns: 70%; /* Grid template columns */
    justify-content: center; /* Horizontally center contents */
    align-items: center; /* Vertically center contents */
    margin-top: 30px; /* Margin top */
}

/* Styles for buttons */
#Button_Login {
    background-color: black; /* Background color */
    border-radius: 15px; /* Border radius */
    color: white; /* Text color */
    font-size: 1.5rem; /* Font size */
    padding: 10px; /* Padding */
    margin-top: 20px; /* Margin top */
    border: none; /* Border */
    width: 100%; /* Full width */
    height: 60px; /* Height */
    cursor: pointer; /* Cursor */
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Box shadow */
}


/* Styles for button hover and active */
#Button_Login:hover {
    background-color: #686464; /* Background color on hover */
    box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.5); /* Box shadow on hover */
    color: white; /* Text color on hover */
}

#Button_Login:active {
    background-color: black; /* Background color on active */
    color: white; /* Text color on active */
}

/* Styles for form checks */
#Body_Login .form-check {
    margin: 0; /* Margin bottom */
    display: flex; /* Flex display */
    justify-content: left; /* Align contents horizontally */
    align-items: center; /* Align contents vertically */
    font-size: 1.3rem; /* Font size */
}

/* Styles for form check inputs */
#Body_Login .form-check-input {
    width: 20px; /* Width */
    height: 20px; /* Height */
    margin-right: 10px; /* Margin right */
    justify-content: center; /* Align contents horizontally */
    margin-bottom: 0 /* Align contents vertically */
}

/* Styles for form check labels */
#Body_Login .form-check-label {
    font-size: 1.2rem; /* Font size */
    margin-bottom: 2%; /* Margin bottom */

}

/* Styles for checked form check inputs */
#Body_Login .form-check-input:checked {
    background-color: #686464; /* Background color on checked */
    border-color: #000000; /* Border color on checked */
}


/*-----------------Part left of Login------------------------*/


/* Styling for the left div container of the login section */
#Div_Left_Login {
    height: 100%; /* Set the height to full height */
    background-color: #DB692F; /* Set the background color */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    display: block; /* Set it as a block element */
    justify-content: center; /* Center the contents horizontally */
    position: sticky; /* Set position to sticky */
    right: 80%; /* Set the right offset to 80% */
    min-width: 400px; /* Set the minimum width to 400 pixels */
    max-width: 100%; /* Set the maximum width to 100% */
    width: 20vw; /* Set the width to 20% of the viewport width */
}

#Input_Group_Parraphal {
    /* Flex container: aligns items along the cross axis (vertically in this case) */
    display: flex;
    align-items: center;
    /* Reset margin */
    margin: 0;
    /* Set font size */
    font-size: 1em;
    /* Set font family */
    font-family: 'Lato', sans-serif;
}

/* Styling for input group when it's invalid */
.input-group.is-invalid {
    /* Set height to 10% of the viewport height */
    height: 10vh;
    /* Center text horizontally */
    text-align: center;
}

/* Styling for invalid feedback */
.invalid-feedback {
    /* Flex container */
    display: flex;
    /* Set position to relative */
    position: relative;
    /* Set height */
    height: 30px;
    /* Set text color to red */
    color: red;
    /* Set font size */
    font-size: 1em;
    /* Set font family */
    font-family: 'Lato', sans-serif;
    /* Align items along the cross axis (vertically) */
    align-items: center;
    /* Center items along the main axis (horizontally) */
    justify-content: center;
    /* Apply border radius */
    border-radius: 10px;
    /* Reset padding */
    padding: 0;
}


/* Styles for the login container */
#Container_Div_Left_Login {
    margin-top: 30vh; /* Margin top */
    flex: 1; /* Flex properties */
}

/* Styles for the heading in the right section */
#Title_Left_Form_Login {
    color: white; /* Set text color to white */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    text-align: center; /* Align text to center */
    margin-top: 20px; /* Add margin on top */
    font: 2.0rem "Poppins Light", sans-serif; /* Set font properties */
    font-weight: lighter; /* Set font weight to lighter */
    display: flex; /* Set display to flex */
    flex-direction: column; /* Set flex direction to column */
}

/* Styling for the logo container */
#Image_Login_Logo_Total {
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    position: absolute; /* Set position to absolute */
    background: #ffffff; /* Set background color */
    height: 30vh; /* Set height to 30% of the viewport height */
    width: 100%; /* Set width to 100% */
    display: flex; /* Set display to flex */
    min-width: 100%; /* Set minimum width to 100% */
    max-width: 100%; /* Set maximum width to 100% */
    border: transparent; /* Set border to transparent */
}


#Logo_Total_Login {
    background: url("../Statics/Img/Logototal.png") 50% 80% no-repeat transparent;
    /* Sets the width of the element to 100% */
    width: 100%;
    /* Sets the height of the element to 100% */
    height: 100%;
    /* Positions the element relative to its normal position */
    position: relative;
    /* Sets padding around the element to 0 */
    padding: 0;
    /* Sets the stacking order of the element */
    z-index: 2;
    /* Adds space below the element */
    margin-bottom: 5%;
    /* Sets the background color to transparent */
    /* Removes border */
    border: none;
}

#H5_Email_Or_Passwprd_Incorrect {
    /* Set top and bottom margin to 25% of the containing block's height, no left or right margin */
    margin: 25% 0;
    /* Set background color to white */
    background-color: white;
    /* Set text color to red */
    color: red;
    /* Set height to 50px */
    height: 50px;
    /* Flex container: center items horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Apply box shadow */
    box-shadow: black 1px 1px 5px;
}

/* Styling for loading spinner */
.loadingspinner {
    /* Custom properties for spinner animation */
    --square: 26px;
    --offset: 30px;
    --duration: 2.4s;
    --delay: 0.2s;
    --timing-function: ease-in-out;
    --in-duration: 0.4s;
    --in-delay: 0.1s;
    --in-timing-function: ease-out;
    /* Set width and height based on custom properties */
    width: calc(3 * var(--offset) + var(--square));
    height: calc(2 * var(--offset) + var(--square));
    /* Reset padding */
    padding: 0;
    /* Set top margin to 10px, auto right and left margins, bottom margin to 30px */
    margin: 10px auto 30px;
    /* Set position to relative */
    position: relative;
}

.loadingspinner div {
    /* Display as inline block */
    display: inline-block;
    /* Set background color to black */
    background: black;
    /* Remove borders */
    border: none;
    /* Apply border radius */
    border-radius: 2px;
    /* Set width and height based on custom properties */
    width: var(--square);
    height: var(--square);
    /* Set position to absolute */
    position: absolute;
    /* Reset padding and margin */
    padding: 0;
    margin: 0;
    /* Set font size to 6pt */
    font-size: 6pt;
    /* Set text color to black */
    color: black;
}

.loadingspinner #square1 {
    /* Calculate left position based on custom property */
    left: calc(0 * var(--offset));
    /* Calculate top position based on custom property */
    top: calc(0 * var(--offset));
    /* Apply animation for square1 */
    animation: square1 var(--duration) var(--delay) var(--timing-function) infinite,
    squarefadein var(--in-duration) calc(1 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square2 {
    /* Calculate left position based on custom property */
    left: calc(0 * var(--offset));
    /* Calculate top position based on custom property */
    top: calc(1 * var(--offset));
    /* Apply animation for square2 */
    animation: square2 var(--duration) var(--delay) var(--timing-function) infinite,
    squarefadein var(--in-duration) calc(1 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square3 {
    /* Calculate left position based on custom property */
    left: calc(1 * var(--offset));
    /* Calculate top position based on custom property */
    top: calc(1 * var(--offset));
    /* Apply animation for square3 */
    animation: square3 var(--duration) var(--delay) var(--timing-function) infinite,
    squarefadein var(--in-duration) calc(2 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square4 {
    /* Calculate left position based on custom property */
    left: calc(2 * var(--offset));
    /* Calculate top position based on custom property */
    top: calc(1 * var(--offset));
    /* Apply animation for square4 */
    animation: square4 var(--duration) var(--delay) var(--timing-function) infinite,
    squarefadein var(--in-duration) calc(3 * var(--in-delay)) var(--in-timing-function) both;
}

.loadingspinner #square5 {
    /* Calculate left position based on custom property */
    left: calc(3 * var(--offset));
    /* Calculate top position based on custom property */
    top: calc(1 * var(--offset));
    /* Apply animation for square5 */
    animation: square5 var(--duration) var(--delay) var(--timing-function) infinite,
    squarefadein var(--in-duration) calc(4 * var(--in-delay)) var(--in-timing-function) both;
}


@keyframes square1 {
    /* Keyframe at 0% of the animation duration */
    0% {
        /* Set the left position to the result of the calculation 0 * var(--offset) */
        left: calc(0 * var(--offset));
        /* Set the top position to the result of the calculation 0 * var(--offset) */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at approximately 8.333% of the animation duration */
    8.333% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(0 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at 100% of the animation duration */
    100% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(0 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }
}

@keyframes square2 {
    /* Keyframe at 0% of the animation duration */
    0% {
        /* Set the left position to the result of the calculation 0 * var(--offset) */
        left: calc(0 * var(--offset));
        /* Set the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 8.333% of the animation duration */
    8.333% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(0 * var(--offset));
        /* Update the top position to the result of the calculation 2 * var(--offset) */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 16.67% of the animation duration */
    16.67% {
        /* Update the left position to the result of the calculation 1 * var(--offset) */
        left: calc(1 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 25.00% of the animation duration */
    25.00% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 83.33% of the animation duration */
    83.33% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 91.67% of the animation duration */
    91.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Update the top position to the result of the calculation 0 * var(--offset) */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at 100% of the animation duration */
    100% {
        /* Set the left position to the result of the calculation 0 * var(--offset) */
        left: calc(0 * var(--offset));
        /* Set the top position to the result of the calculation 0 * var(--offset) */
        top: calc(0 * var(--offset));
    }
}


@keyframes square3 {
    /* Keyframes for the animation */
    /* Keyframe at 0% and 100% of the animation duration */
    0%, 100% {
        /* Set the left position to the result of the calculation 1 * var(--offset) */
        left: calc(1 * var(--offset));
        /* Set the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 16.67% of the animation duration */
    16.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 25.00% of the animation duration */
    25.00% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Update the top position to the result of the calculation 0 * var(--offset) */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at approximately 33.33% of the animation duration */
    33.33% {
        /* Update the left position to the result of the calculation 2 * var(--offset) */
        left: calc(2 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at approximately 41.67% of the animation duration */
    41.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 66.67% of the animation duration */
    66.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 75.00% of the animation duration */
    75.00% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Update the top position to the result of the calculation 2 * var(--offset) */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 83.33% of the animation duration */
    83.33% {
        /* Update the left position to the result of the calculation 1 * var(--offset) */
        left: calc(1 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 91.67% of the animation duration */
    91.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(1 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }
}

@keyframes square4 {
    /* Keyframe at 0% of the animation duration */
    0% {
        /* Set the left position to the result of the calculation 2 * var(--offset) */
        left: calc(2 * var(--offset));
        /* Set the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 33.33% of the animation duration */
    33.33% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 41.67% of the animation duration */
    41.67% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Update the top position to the result of the calculation 2 * var(--offset) */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 50.00% of the animation duration */
    50.00% {
        /* Update the left position to the result of the calculation 3 * var(--offset) */
        left: calc(3 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(2 * var(--offset));
    }

    /* Keyframe at approximately 58.33% of the animation duration */
    58.33% {
        /* Update the left position to the result of the calculation 3 * var(--offset) */
        left: calc(3 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at 100% of the animation duration */
    100% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(3 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }
}

@keyframes square5 {
    /* Keyframe at 0% of the animation duration */
    0% {
        /* Set the left position to the result of the calculation 3 * var(--offset) */
        left: calc(3 * var(--offset));
        /* Set the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 50.00% of the animation duration */
    50.00% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(3 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at approximately 58.33% of the animation duration */
    58.33% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(3 * var(--offset));
        /* Update the top position to the result of the calculation 0 * var(--offset) */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at approximately 66.67% of the animation duration */
    66.67% {
        /* Update the left position to the result of the calculation 2 * var(--offset) */
        left: calc(2 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(0 * var(--offset));
    }

    /* Keyframe at approximately 75.00% of the animation duration */
    75.00% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Update the top position to the result of the calculation 1 * var(--offset) */
        top: calc(1 * var(--offset));
    }

    /* Keyframe at 100% of the animation duration */
    100% {
        /* Keep the left position the same as the previous keyframe */
        left: calc(2 * var(--offset));
        /* Keep the top position the same as the previous keyframe */
        top: calc(1 * var(--offset));
    }
}

@keyframes squarefadein {
    /* Keyframe at 0% of the animation duration */
    0% {
        /* Set the scale transformation to 0.75 (75% of the original size) */
        transform: scale(0.75);
        /* Set the opacity to 0 (fully transparent) */
        opacity: 0.0;
    }

    /* Keyframe at 100% of the animation duration */
    100% {
        /* Set the scale transformation to 1.0 (original size) */
        transform: scale(1.0);
        /* Set the opacity to 1 (fully opaque) */
        opacity: 1.0;
    }
}


/*--------------------------Part right of Login------------------------------------*/
/* Styling for the right div container of the login section */
#Div_Right_Login {
    height: 100%; /* Set height to full height */
    width: 80%; /* Set width to 80% */
    background-color: #2A364E; /* Set background color */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    display: flex; /* Set it as a flex container */
    position: sticky; /* Set position to sticky */
}



/* Media query for screen widths up to 760px */
@media (max-width: 760px) {
    /* Hide a div */
    #Div_Right_Login {
        display: none;
    }

    /* Adjust width of a div */
    #Div_Left_Login {
        position: fixed; /* Set position to fixed */
        width: 100vw; /* Set width to 100% of the viewport width */
        display: flex; /* Set it as a flex container */
        left: 0; /* Set left offset to 0 */
    }
}



