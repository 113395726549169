/* Navbar admin left */
#Navbar_Admin_Left {
    position: fixed; /*Change the position to fixed*/
    width: 5%; /*Change the width to 5%*/
    height: 100vh; /*Change the height to 100vh*/
    background-color: #1A283F; /*Change the background color to #2A364E*/
    z-index: 4; /*Change the z-index to 4*/
    border: transparent; /*Change the border to transparent*/
}

/* Styles for Navbar_Admin_Left container */
#Navbar_Admin_Left {
    /* Change the position to fixed */
    position: fixed;
    /* Change the width to 5% */
    width: 5%;
    /* Change the height to 100vh */
    height: 100vh;
    /* Change the background color to #2A364E */
    background-color: #1A283F;
    /* Change the z-index to 4 */
    z-index: 4;
    /* Change the border to transparent */
    border: transparent;
}

/* Styles for children of Navbar_Admin_Left */
#Navbar_Admin_Left > div {
    /* Change the position to absolute */
    position: absolute;
    /* Set the width to 100% */
    width: 100%;
    /* Set the background to transparent */
    background: transparent;
    /* Display flex items centered */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Set text color to white */
    color: white;


}

/* Styles for Div_Navbar_Admin_Left_Icon_Profile */
#Div_Navbar_Admin_Left_Icon_Profile {
    border-radius: 20%;
    /* Set top position to 2% */
    top: 2%;
    /* Set bottom position to 90% */
    bottom: 90%;

}

/* Styles for Div_Navbar_Admin_Left_Icon_Home_Library */
#Div_Navbar_Admin_Left_Icon_Home_Library {
    /* Set top position to 70% */
    top: 70%;
}

/* Styles for Div_Navbar_Admin_Left_Icon_Virtual_Library */
#Div_Navbar_Admin_Left_Icon_Virtual_Library {
    /* Set top position to 23% */
    top: 23%;
}

/* Styles for Div_Navbar_Admin_Left_Icon_Bar_Chart */
#Div_Navbar_Admin_Left_Icon_Bar_Chart {
    /* Set top position to 30% */
    top: 30%;
}

/* Styles for Div_Navbar_Admin_Left_Icon_Setting */
#Div_Navbar_Admin_Left_Icon_Setting {
    /* Set top position to 80% */
    top: 80%;
    /* Set position to relative */
    position: relative;
    /* Display flex items centered */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles for Div_Navbar_Admin_Left_Icon_Exit */
#Div_Navbar_Admin_Left_Icon_Exit {
    /* Set top position to 90% */
    top: 90%;
}

#Svg_Navbar_Admin_Left_Icon_Bar_Chart,
#Svg_Navbar_Admin_Left_Icon_Exit,
#Svg_Navbar_Admin_Left_Icon_Home,
#Svg_Navbar_Admin_Left_Icon_Profile,
#Svg_Navbar_Admin_Left_Icon_Setting,
#Svg_Navbar_Admin_Left_Icon_Virtual_Library {
    display: flex;
    position: relative;
    /*applied box shadow*/
    width: 90%;
    /* Set height to 50% */
    border-radius: 20%;
    /* Set border radius to 20% */
    fill: white;
    /* Set fill color to white */
    transition: all 0.5s;
}

/* Hover styles for svg elements */
#Svg_Navbar_Admin_Left_Icon_Profile:hover,
#Svg_Navbar_Admin_Left_Icon_Virtual_Library:hover,
#Svg_Navbar_Admin_Left_Icon_Bar_Chart:hover,
#Svg_Navbar_Admin_Left_Icon_Setting:hover,
#Svg_Navbar_Admin_Left_Icon_Exit:hover,
#Svg_Navbar_Admin_Left_Icon_Home:hover {

    /* Apply box shadow */
    box-shadow: 1px 1px 5px 1px white;
    /* Apply border radius */
    background-color: #1c282f;
}


/* Styles for the search icon */
#Svg_Navbar_Admin_Left_Icon_Virtual_Library_Search {
    position: absolute; /*Change the position to absolute*/
    left: 0; /*Change the left to 0*/
    height: 100%; /*Change the height to 100%*/
    width: 80%; /*  Change the width to 80% */
    opacity: 0; /*Change the opacity to 0*/
    visibility: hidden; /*Change the visibility to hidden*/
    background-color: white; /*Change the background color to white*/
    fill: white; /*Change the fill to white*/
    padding: 10px; /*Change the padding to 10px*/
    border-radius: 50px; /*Change the border radius to 50px*/
    transition: all 0.5s; /*Change the transition to all 0.5s*/
    z-index: 1; /*Change the z-index to 1*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /*Change the box shadow to 0 2px 4px rgba(0, 0, 0, 0.2)*/
}


/* Hover styles for the search icon */
#Div_Navbar_Admin_Left_Icon_Virtual_Library:hover #Svg_Navbar_Admin_Left_Icon_Virtual_Library_Search {
    left: 150%; /*Change the left to 100%*/
    opacity: 1; /*Change the opacity to 1*/
    visibility: visible; /*Change the visibility to visible*/
    background-color: #DB692F; /*Change the background color to #DB692F*/
}

/* Hover styles for the search icon */
#Svg_Navbar_Admin_Left_Icon_Virtual_Library_Search:hover {
    background-color: #DB692F; /*Change the background color to #DB692F*/
    box-shadow: 1px 1px 5px 1px #101010; /*Change the box shadow to 1px 1px 5px 1px #101010*/
}


/* Styles for tooltips */
#Tooltip_Navbar_Left_Home,
#Tooltip_Navbar_Left_Library,
#Tooltip_Navbar_Left_Bar_Chart,
#Tooltip_Navbar_Left_Profile,
#Tooltip_Navbar_Left_Settings,
#Tooltip_Navbar_Left_Exit{
    position: absolute; /*Change the position to absolute*/
    left: 50%; /*Change the left to 50%*/
    visibility: hidden; /*Change the visibility to hidden*/
    transition: all 0.5s; /*Change the transition to all 0.5s*/
}

/* Styles for tooltips */
#Div_Navbar_Admin_Left_Icon_Home_Library:hover #Tooltip_Navbar_Left_Home,
#Div_Navbar_Admin_Left_Icon_Virtual_Library:hover #Tooltip_Navbar_Left_Library,
#Div_Navbar_Admin_Left_Icon_Bar_Chart:hover #Tooltip_Navbar_Left_Bar_Chart,
#Div_Navbar_Admin_Left_Icon_Profile:hover #Tooltip_Navbar_Left_Profile,
#Div_Navbar_Admin_Left_Icon_Setting:hover #Tooltip_Navbar_Left_Settings,
#Div_Navbar_Admin_Left_Icon_Exit:hover #Tooltip_Navbar_Left_Exit {
    visibility: visible; /*Change the visibility to visible*/
    left: 100%; /*Change the left to 100%*/
    background-color: #DB692F; /*Change the background color to #DB692F*/
    position: absolute; /*Change the position to absolute*/
    top: 0; /*Change the top to 0*/
    transform: translate(-60%, -50%); /*Change the transform to translate(-60%, -50%)*/
    padding: 0.6em 0.6em; /*Change the padding to 0.6em 0.6em*/
    opacity: 1; /*Change the opacity to 1*/
    pointer-events: none; /*Change the pointer events to none*/
    transition: all 0.3s; /*Change the transition to all 0.3s*/
    clip-path: polygon(0 0, 100% 0, 100% 86%, 40% 86%, 17% 100%, 15% 87%, 0 87%); /*Change the clip path to polygon(0 0, 100% 0, 100% 86%, 40% 86%, 17% 100%, 15% 87%, 0 87%)*/
}


/* Styles for the tooltip text */
#Div_Navbar_Admin_Left_Icon_Bar_Chart:hover #Tooltip_Navbar_Left_Bar_Chart{
    justify-content: center; /*Change the justify content to center*/
    align-items: center; /* Change the align items to center */
    padding: 20px; /*Change the padding to 20px*/
    transform: translate(-20%, -90%); /*Change the transform to translate(-20%, -90%)*/
}


/* Styles for Section_Navbar_Profile_Virtual_Library */
#Section_Navbar_Profile_Virtual_Library {
    /* Position the card fixed */
    position: fixed;
    /* Set width to 100vw */
    width: 100vw;
    /* Set height to 100vh */
    height: 100vh;
    /* Set background color with transparency */
    background-color: rgba(0, 0, 0, 0.5);
    /* Center contents horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Display as flexbox */
    display: flex;
    /* Set z-index to 3 */
    z-index: 3;
}

/* Styles for Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library {
    /* Set width to 30% */
    width: 30%;
    /* Set height to 40% */
    height: 40%;
    /* Set background color */
    background: #f0f0f0;
    /* Apply border radius */
    border-radius: 10px;
    /* Center text */
    text-align: center;
    /* Transition effect for hover */
    transition: all 0.5s ease;
}

/* Hover styles for Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library:hover {
    /* Increase height on hover */
    height: 70%;
    /* Increase width on hover */
    width: 70%;
    /* Apply box shadow on hover */
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.623);
    /* Change background color on hover */
    background-color: #2a364e;
    /* Display as flexbox */
    display: flex;
    /* Remove padding on hover */
    padding: 0;
}


/*button close*/

/* Styles for the close button on the profile admin card */
#Button_Close_Profile_Admin_Card {
    /* Position the button at the top-right corner */
    right: 0;
    top: 10%;
    position: absolute;
    /* Set width and height of the button */
    width: 4em;
    height: 4em;
    /* Remove border */
    border: none;
    /* Make background transparent */
    background-color: transparent;
    /* Apply border radius */
    border-radius: 5px;
    /* Transition effect for background */
    transition: background 0.5s;
    /* Set z-index */
    z-index: 4;
}

/* Styles for the X icon */
.X {
    /* Position the top line of the X */
    position: absolute;
    top: 50%;
    left: 50%;
    /* Set width and height of the line */
    width: 2em;
    height: 2px;
    /* Set background color */
    background-color: rgb(255, 255, 255);
    /* Transform to create the X shape */
    transform: translateX(-50%) rotate(45deg);
}

/* Styles for the Y icon */
.Y {
    /* Position the bottom line of the X */
    position: absolute;
    top: 50%;
    left: 50%;
    /* Set width and height of the line */
    width: 2em;
    height: 2px;
    /* Set background color */
    background-color: #fff;
    /* Transform to create the X shape */
    transform: translateX(-50%) rotate(-45deg);
}

/* Styles for the close button text */
.close {
    /* Position the text below the X icon */
    position: absolute;
    /* Display flex to center text */
    display: flex;
    padding: 0.8rem 1.5rem;
    align-items: center;
    justify-content: center;
    /* Center the text horizontally */
    transform: translateX(-50%);
    /* Position the text above the button */
    top: -70%;
    left: 50%;
    /* Set width and height of the text */
    width: 3em;
    height: 1.7em;
    /* Set font size */
    font-size: 12px;
    /* Set background color */
    background-color: rgb(19, 22, 24);
    /* Set text color */
    color: rgb(187, 229, 236);
    /* Remove border */
    border: none;
    /* Apply border radius */
    border-radius: 3px;
    /* Disable pointer events */
    pointer-events: none;
    /* Set initial opacity to 0 */
    opacity: 0;
}

/* Hover styles for the close button */
#Button_Close_Profile_Admin_Card:hover {
    /* Change background color on hover */
    background-color: rgb(211, 21, 21);
}

/* Active styles for the close button */
#Button_Close_Profile_Admin_Card:active {
    /* Change background color when clicked */
    background-color: rgb(130, 0, 0);
}

/* Animation for the close button text */
#Button_Close_Profile_Admin_Card:hover > .close {
    /* Apply animation */
    animation: close 0.2s forwards 0.25s;
}

/* Keyframes for close animation */
@keyframes close {
    100% {
        /* At 100% of the animation, set opacity to 1 */
        opacity: 1;
    }
}

/* Styles for the blob inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library .blob {
    /* Set height and width of the blob */
    height: 20px;
    width: 75%;
    /* Apply border radius */
    border-radius: 0 0 30px 30px;
    /* Center the blob horizontally */
    margin: 0 auto;
    /* Set background color */
    background-color: #2a364e;
    /* Make blob visible */
    visibility: visible;
    /* Transition effect for all properties */
    transition: all 0.3s;
}

/* Hover styles for the blob */
#Card_Navbar_Profile_Virtual_Library:hover .blob {
    /* Set height to 0 on hover */
    height: 0;
}

/* Styles for the image inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library .img {
    /* Display the image as a flexbox */
    display: flex;
    /* Center the image horizontally */
    margin: 10% auto;
    /* Set width and height of the image */
    width: 20%;
    height: 30%;
    /* Apply border radius */
    border-radius: 45%;
    /* Set font size */
    font-size: 11px;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
    align-items: center;
    /* Transition effect for all properties */
    transition: all 0.5s;
}

/* Hover styles for the image */
#Card_Navbar_Profile_Virtual_Library:hover .img {
    /* Expand image on hover */
    width: 100%;
    height: 70%;
    /* Apply border radius */
    border-radius: 10px 0 0;
    /* Reset margin */
    margin: 0 auto;
    /* Transition effect */
    transition: all 1s;
}

/* Styles for the h2 element inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library h2 {
    /* Set font family */
    font-family: 'Lato', sans-serif;
    /* Set text color */
    color: black;
    /* Add padding */
    padding: 15px 10px;
    /* Set font size */
    font-size: 25px;
    /* Transition effect for all properties */
    transition: all 0.1s;
    /* Set z-index */
    z-index: 99;
    /* Set line height */
    line-height: 17px;
}

/* Styles for the span element inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library span {
    /* Set font size */
    font-size: 18px;
}

/* Hide h2 element on hover */
#Card_Navbar_Profile_Virtual_Library:hover h2 {
    /* Hide h2 element on hover */
    display: none;
}

/* Styles for div elements inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library div {
    /* Display as flexbox */
    display: flex;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Add padding */
    padding: 10px;
    /* Transition effect for all properties */
    transition: all 2s;
    /* Arrange flex items in column direction */
    flex-direction: column;
}

/* Styles for label elements inside Card_Navbar_Profile_Virtual_Library */
#Card_Navbar_Profile_Virtual_Library label {
    /* Hide label elements */
    display: none;
}

/* Display div elements on hover */
#Card_Navbar_Profile_Virtual_Library:hover div {
    /* Display div elements as flexbox */
    display: flex;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Add padding */
    padding: 10px;
    /* Transition effect */
    transition: all 0.5s;
    /* Arrange flex items in row direction */
    flex-direction: row;
}

/* Display label elements on hover */
#Card_Navbar_Profile_Virtual_Library:hover label {
    /* Add padding */
    padding: 2%;
    /* Set position to relative */
    position: relative;
    /* Set text color */
    color: white;
    /* Transition effect */
    transition: all 0.5s;
    /* Display label elements as flexbox */
    display: flex;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Set font size */
    font-size: 1.2em;
}

/* Styles for Section_Navbar_Settings_Dropdown_Virtual_Library */
#Section_Navbar_Settings_Dropdown_Virtual_Library {
    /* Position the section fixed */
    position: fixed;
    /* Align section to the right edge */
    right: 0;
    /* Set width to full viewport width */
    width: 100vw;
    /* Set height to full viewport height */
    height: 100vh;
    /* Set background color */
    background-color: white;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Display section as flexbox */
    display: flex;
    /* Set z-index */
    z-index: 3;
}


/* Styles for the card representing a selection in settings */
#Card_Selection_Of_Settings {
    /* Display as flexbox with column direction */
    display: flex;
    flex-direction: column;
    /* Set height to 40% of parent */
    height: 40%;
    /* Set background color */
    background-color: white;
    /* Apply border radius */
    border-radius: 10px;
    /* Apply box shadow */
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.08),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
    /* Transition effect for all properties */
    transition: all 0.3s;
    /* Change cursor to pointer on hover */
    cursor: pointer;
    /* Box sizing */
    box-sizing: border-box;
    /* Add padding */
    padding: 10px;
    /* Set position to relative */
    position: relative;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Set width to 25% of parent minus 20px */
    width: calc(25% - 20px);
    /* Add right margin */
    margin-right: 20px;
    /* Add bottom margin */
    margin-bottom: 20px;
    /* Prevent horizontal overflow */
    overflow: hidden;
    /* Set left offset to 0.5% */
    left: 0.5%;
}

/* Remove right margin for the last card in each row */
#Card_Selection_Of_Settings:last-child {
    margin-right: 0;
}

/* Hover styles for the card */
#Card_Selection_Of_Settings:hover {
    /* Move card upwards on hover */
    transform: translateY(-10px);
    /* Apply box shadow */
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1),
    -4px -4px 12px rgba(0, 0, 0, 0.08);
}

/* Styles for the image inside the card */
#Card_Image_Selection_Of_Settings {
    /* Set width and height to 100% */
    width: 100%;
    height: 64%;
    /* Apply border radius */
    border-radius: 10px;
    /* Add bottom margin */
    margin-bottom: 12px;
    /* Prevent overflow */
    overflow: hidden;
    /* Set background image */
    background: url("../Statics/Img/Logototal.png") no-repeat center center;
    /* Display as flexbox */
    display: flex;
    /* Center content horizontally and vertically */
    align-items: center;
    justify-content: center;
}

/* Styles for the title inside the card */
#Tittle_Card_Selection_Of_Settings {
    /* Remove margins */
    margin: 0;
    /* Set font size */
    font-size: 17px;
    /* Set font family */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    /* Set font weight */
    font-weight: 600;
    /* Set text color */
    color: #000000;
    /* Change cursor to default */
    cursor: default;
    /* Set orientation to vertical */
    -webkit-box-orient: vertical;
    /* Prevent overflow */
    overflow: hidden;
    /* Display as a single line */
    display: -webkit-box;
    /* Set maximum line count to 1 */
    -webkit-line-clamp: 1;
    /* Maximum line count for newer browsers */
    line-clamp: 1;
}

/* Styles for the description inside the card */
#Des_Card_Selection_Of_Settings {
    /* Set orientation to vertical */
    -webkit-box-orient: vertical;
    /* Prevent overflow */
    overflow: hidden;
    /* Display as flexbox */
    display: -webkit-box;
    /* Set maximum line count to 3 */
    -webkit-line-clamp: 3;
    /* Maximum line count for newer browsers */
    line-clamp: 3;
    /* Remove margins */
    margin: 0;
    /* Set font size */
    font-size: 13px;
    /* Set font family */
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    /* Set text color */
    color: #000000;
    /* Change cursor to default */
    cursor: default;
}

/* Styles for the image container inside the card */
#Card_Image_Selection_Of_Settings {
    /* Display as flexbox */
    display: flex;
    /* Wrap content */
    flex-wrap: wrap;
    /* Distribute items evenly */
    justify-content: space-between;
}

/* Styles for the library navigation div */
#Div_Library_Navbar {
    /* Set text color */
    color: white;
    /* Set background color */
    background: #2a364e;
    /* Display as flexbox */
    display: flex;
    /* Center content horizontally and vertically */
    justify-content: center;
    align-items: center;
    /* Set position to absolute */
    position: absolute;
    /* Set left offset to 5% */
    left: 5%;
    /* Set height to full viewport height */
    height: 100vh;
    /* Set width to 40px */
    width: 40px;
    /* Apply clip path */
    clip-path: polygon(61% 26%, 88% 48%, 64% 70%, 55% 100%, 0 100%, 0 0, 50% 0);
    /* Set z-index */
    z-index: 3;
    /* Remove border */
    border: transparent;
}

/* Media query for screens with max width of 1200px */
@media screen and (max-width: 1200px) {
    /* Adjust width of Navbar_Admin_Left */
    #Navbar_Admin_Left {
        width: 7%;
    }

    /* Adjust width and height of Card_Navbar_Profile_Virtual_Library */
    #Card_Navbar_Profile_Virtual_Library {
        width: 50%;
        height: 50%;
    }

    /* Adjust width and height of image inside Card_Navbar_Profile_Virtual_Library */
    #Card_Navbar_Profile_Virtual_Library .img {
        width: 50%;
        height: 50%;
    }

    /* Adjust font size of h2 inside Card_Navbar_Profile_Virtual_Library */
    #Card_Navbar_Profile_Virtual_Library h2 {
        font-size: 20px;
    }

    /* Adjust font size of span inside Card_Navbar_Profile_Virtual_Library */
    #Card_Navbar_Profile_Virtual_Library span {
        font-size: 15px;
    }
}

/* Media query for screens with max width of 800px */
@media screen and (max-width: 800px) {
    /* Adjust styles for Navbar_Admin_Left */
    #Navbar_Admin_Left {
        /* Set position to absolute */
        position: absolute;
        /* Set width to full viewport width */
        width: 100%;
        /* Set height to 10% of viewport height */
        height: 10%;
        /* Display as flexbox */
        display: flex;
        /* Center content horizontally and vertically */
        justify-content: center;
        align-items: center;
        /* Set flex direction to row */
        flex-direction: row;
        /* Prevent wrapping */
        flex-wrap: nowrap;
    }

    /* Adjust styles for div inside Navbar_Admin_Left */
    #Navbar_Admin_Left > div {
        /* Display as flexbox */
        display: flex;
        /* Set flex direction to row */
        flex-direction: row;
        /* Distribute items evenly */
        justify-content: space-around;
        /* Set position to static */
        position: static;
        /* Set height to 100% of parent */
        height: 100%;
        /* Set text color */
        color: white;
    }

    /* Adjust styles for svg elements inside Navbar_Admin_Left */
    #Navbar_Admin_Left > svg {
        /* Set height to 100% of parent */
        height: 100%;
    }

    /* Adjust styles for svg elements */
    svg {
        /* Set height to 100% of parent */
        height: 100%;
    }

    /* Hover styles for svg elements */
    svg:hover {
        /* Apply box shadow */
        box-shadow: white 1px 1px 5px 1px;
        /* Apply border radius */
        border-radius: 20%;
        /* Adjust width and height */
        width: 90%;
        height: 50%;
    }
}
