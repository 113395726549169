#Div_Navbar_Top_Admin_Widgets_Messages{
    display: none;
}

/* Navbar AdminVideos Top */
#Navbar_Admin_Top {
    background-color: white;
    position: fixed;
    width: 100vw;
    height: 10vh;
    left: 5%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-flow: row;
    justify-content: space-between;
    z-index: 2;

}

#Section_Navbar_Top_Admin_Widgets {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 10%;
    left: 30%;
    position: relative;
    align-items: center;
}

#Div_Navbar_Top_Admin_Videos_Widgets_Notifications {
    margin: 2%;
    position: relative;
}


#Section_Navbar_Top_Admin_Profile {
    height: 100%;
    width: 20%;
    right: 5vw;
    top: 20%;
    position: relative;
    display: flex;
    flex-direction: row;
}

#Div_Navbar_Top_Admin_Profile_Icon {
    height: 60%;
    width: 60px;
    border-radius: 10pc;
    margin: 1% 2%;
    background: url("../Statics/Img/IMG_Example_Profile.jpg") no-repeat center center / cover;
}

#Div_Navbar_Top_Admin_Profile_Info {
    right: 5%;
    display: flow;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1%;
}


/* this is a recreation of twitter search in css */
#Form_Navbar_Top_Admin_Search {
    --input-text-color: #fff;
    --input-bg-color: #283542;
    --focus-input-bg-color: transparent;
    --text-color: #949faa;
    --active-color: #1b9bee;
    --width-of-input: 200px;
    --inline-padding-of-input: 1.2em;
    --gap: 0.9rem;
    --transition: 0.3s;
}

/* form style */
#Form_Navbar_Top_Admin_Search {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    width: var(--width-of-input);
    position: relative;
    left: 60vw;
}

/* a fancy bg for showing background and border when focus. */
#Fancy_BG_Navbar_Top_Admin_Search {
    position: absolute;
    width: 100%;
    inset: 0;
    background: var(--input-bg-color);
    border-radius: 30px;
    top: 20%;
    height: 60%;
    z-index: -1;
    pointer-events: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

/* label styling */
#Label_Navbar_Top_Admin_Search {
    width: 100%;
    padding: 0.8em;
    height: 40px;
    padding-inline: var(--inline-padding-of-input);
    display: flex;
    align-items: center;
}

#Search_Navbar_Top_Admin_Search, .close-btn {
    position: absolute;
}

/* styling search-icon */
#Search_Navbar_Top_Admin_Search {
    fill: var(--text-color);
    left: var(--inline-padding-of-input);
}

/* svg -- size */
#SVG_Navbar_Top_Admin_Search {
    width: 17px;
    display: block;
}

/* styling of close button */
.close-btn {
    border: none;
    right: var(--inline-padding-of-input);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0.1em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--active-color);
    opacity: 0;
    visibility: hidden;
}

/* styling of input */
#Input_Navbar_Top_Admin_Search {
    color: var(--input-text-color);
    width: 100%;
    margin-inline: min(2em, calc(var(--inline-padding-of-input) + var(--gap)));
    background: none;
    border: none;
}

#Input_Navbar_Top_Admin_Search:focus {
    outline: none;
}

#Input_Navbar_Top_Admin_Search::placeholder {
    color: var(--text-color)
}

/* input background change in focus */
#Input_Navbar_Top_Admin_Search:focus ~ .fancy-bg {
    border: 1px solid var(--active-color);
    background: var(--focus-input-bg-color);
}

/* search icon color change in focus */
#Input_Navbar_Top_Admin_Search:focus ~ .search {
    fill: var(--active-color);
}

/* showing close button when typing */
#Input_Navbar_Top_Admin_Search:valid ~ .close-btn {
    opacity: 1;
    visibility: visible;
}

/* this is for the default background in input,when selecting autofill options -- you can remove this code if you do not want to override the browser style.  */
#Input_Navbar_Top_Admin_Search:-webkit-autofill,
#Input_Navbar_Top_Admin_Search:-webkit-autofill:hover,
#Input_Navbar_Top_Admin_Search:-webkit-autofill:focus,
#Input_Navbar_Top_Admin_Search:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
}

