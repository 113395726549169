/* Styling for the carousel component */
#Carousel_Login {
    width: 100%; /* Set width to 100% */
    height: 100%; /* Set height to 100% */
    padding: 0; /* Remove padding */
}

.carousel-control-next {
    /* Set the width of the button */
    width: 100px;
    /* Set the height of the button */
    height: 100px;
    /* Position the button vertically at 45% from the top of its container */
    top: 45%;
    /* Position the button horizontally at 4% from the right of its container */
    right: 4%;
    /* Apply a border radius to give rounded corners */
    border-radius: 20px;
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
}

.carousel-control-prev {
    /* Set the width of the button */
    width: 100px;
    /* Set the height of the button */
    height: 100px;
    /* Position the button vertically at 45% from the top of its container */
    top: 45%;
    /* Position the button horizontally at 4% from the left of its container */
    left: 4%;
    /* Apply a border radius to give rounded corners */
    border-radius: 20px;
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
}

.carousel-control-prev:hover {
    /* Set background color to semi-transparent black on hover */
    background-color: rgba(0, 0, 0, 0.70);
    /* Set the width of the button */
    width: 100px;
    /* Set the height of the button */
    height: 100px;
    /* Position the button vertically at 45% from the top of its container */
    top: 45%;
    /* Position the button horizontally at 4% from the left of its container */
    left: 4%;
    /* Apply a border radius to give rounded corners */
    border-radius: 20px;
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
}

.carousel-control-next:hover {
    /* Set background color to semi-transparent black on hover */
    background-color: rgba(0, 0, 0, 0.70);
    /* Set the width of the button */
    width: 100px;
    /* Set the height of the button */
    height: 100px;
    /* Position the button vertically at 45% from the top of its container */
    top: 45%;
    /* Position the button horizontally at 4% from the right of its container */
    right: 4%;
    /* Apply a border radius to give rounded corners */
    border-radius: 20px;
    /* Center the content horizontally */
    justify-content: center;
    /* Center the content vertically */
    align-items: center;
}


/* Fade-in animation */
.fade-in {
    animation: fadeIn 1.5s ease-in-out; /* Apply fadeIn animation with a duration of 1.5 seconds */
}

/* Fade-out animation */
.fade-out {
    animation: fadeOut 1.5s ease-in-out; /* Apply fadeOut animation with a duration of 1.5 seconds */
}

/* Keyframes for fadeIn animation */
@keyframes fadeIn {
    from { /* Start state */
        opacity: 0; /* Set opacity to 0 */
    }
    to { /* End state */
        opacity: 1; /* Set opacity to 1 */
    }
}

/* Keyframes for fadeOut animation */
@keyframes fadeOut {
    from { /* Start state */
        opacity: 1; /* Set opacity to 1 */
    }
    to { /* End state */
        opacity: 0; /* Set opacity to 0 */
    }
}


/*//--------------Views-----------------------------------*/


/*--------------------VIEW 1-----------------------------------*/
/* Styling for the container of View1 in the carousel */
#Container_View1_Login_Carousel {
    display: flex; /* Set display to flex */
    position: fixed; /* Set position to fixed */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    height: 100%; /* Set height to 100% of the viewport height */
    width: 100%; /* Set width to 100% of the viewport width */
}

/* Styling for the left div within View1 in the carousel */
#Div_Left_View1_Carousel_Login {
    right: 40%; /* Set right offset to 40% */
    height: 100%; /* Set height to 100% of the container height */
    width: 60%; /* Set width to 60% of the container width */
    position: absolute; /* Set position to absolute */
    display: flex; /* Set display to flex */
    justify-content: start; /* Align contents to the start */
    align-items: start; /* Align contents to the start */
}


/* Styling for the image container within View1 in the carousel */
/* Styling for the first image container within View1 in the carousel */
#Image1_View1_Login_Carousel {
    /* Use flexbox to center vertically */
    display: flex;
    /* Set position to relative */
    position: relative;
    /* Set z-index for stacking order */
    z-index: 2;
    /* Set left offset */
    left: 22%;
    /* Set top offset */
    top: 30%;
}

/* Styling for images within the first image container */
#Image1_View1_Login_Carousel img {
    /* Adjust width to fill 40% of the container */
    width: 40%;
    /* Adjust height to fill 25% of the container */
    height: 25%;
    /* Adjust image to cover the container */
    object-fit: cover;
    /* Set border radius */
    border-radius: 30px;
}

/* Styling for the second image container within View1 in the carousel */
#Image2_View1_Login_Carousel {
    /* Use flexbox to center vertically */
    display: flex;
    /* Set position to absolute */
    position: absolute;
    /* Set z-index for stacking order */
    z-index: 1;
    /* Set left offset */
    left: 5%;
    /* Set top offset */
    top: 5%;
}

/* Styling for images within the second image container */
#Image2_View1_Login_Carousel img {
    /* Adjust width to fill 45% of the container */
    width: 45%;
    /* Adjust height to fill 25% of the container */
    height: 25%;
    /* Adjust image to cover the container */
    object-fit: cover;
    /* Set border radius */
    border-radius: 30px;
}


/* Styling for the right div container within View1 in the carousel */
#Div_Right_View1_Carousel_Login {
    left: 60%; /* Set left offset to 60% */
    height: 100%; /* Set height to 100% */
    width: 60%; /* Set width to 60% */
    background: transparent; /* Set background to transparent */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    position: fixed; /* Set position to fixed */
}

/* Styling for the section within View1 in the carousel */
#Section_More_View1_Carousel_Login {
    position: relative; /* Set position to relative */
    height: 100%; /* Set height to 100% */
    width: 100%; /* Set width to 100% */
    display: flex; /* Set display to flex */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    flex-direction: column; /* Set flex direction to column */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
    right: 15%; /* Set right offset to 15% */
    top: 25%; /* Set top offset to 25% */
}

/* Styling for the title within View1 in the carousel */
#Tittle_More_View1_Carousel_Login {
    color: white; /* Set text color to white */
    font-size: 2.5rem; /* Set font size */
    font-family: "lato", sans-serif; /* Set font family */
    font-weight: bold; /* Set font weight to bold */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    text-align: center; /* Align text to center */
}


/* Styling for the button within View1 in the carousel */
#Button_More_View1_Carousel_Login {
    display: flex; /* Set display to flex */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    object-fit: cover; /* Fit the content to the container */
    background-color: #DB692F; /* Set background color */
    color: white; /* Set text color to white */
    font-size: 1.5rem; /* Set font size */
    margin-top: 20px;/* Set top margin */
    border: none; /* Remove border */
    width: 25%; /* Set width to 25% */
    height: 60px; /* Set height to 60px */
    cursor: pointer; /* Set cursor to pointer */
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Add box shadow */
    z-index: auto; /* Set Z-index to auto */
    transition: transform 0.2s ease; /* Add transition */
}

/* Hover effect for the button within View1 in the carousel */
#Button_More_View1_Carousel_Login:hover {
    transform: scale(1.1); /* Apply scale transformation */
}

/* Styling for the first image container within View1 in the carousel */
#Div_More_Total_Construction {
    padding: 2%; /* Set padding */
    background-color: white; /* Set background color to white */
    height: 80%; /* Set height to 80% */
    width: 50%; /* Set width to 50% */
    position: absolute; /* Set position to absolute */
    bottom: 5%; /* Set bottom offset to 5% */
    z-index: 3; /* Set Z-index to 3 */
    display: flex; /* Set display to flex */
    flex-direction: column; /* Set flex direction to column */
    justify-content: center; /* Center contents vertically */
    align-items: center; /* Center contents horizontally */
    box-shadow: 1px 1px 5px rgba(234, 105, 38, 0.7); /* Add box shadow */
    border-radius: 20px; /* Add border radius */
}

/* Styling for the button within the first image container */
#Button_More_View1_Carousel_Login.Dropdown {
    display: none; /* Hide the button */
}

/* Styling for the paragraph within View1 in the carousel */
#Parraphal_More_Total_Construction {
    font-size: 100%; /* Set font size */
    font-family: 'Arial', serif; /* Set font family */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    justify-content: justify; /* Justify content */
    display: flex; /* Set display to flex */
    align-items: justify; /* Align items to justify */
    text-align: justify; /* Align text to justify */
    flex-direction: column; /* Set flex direction to column */
    line-height: 1.6; /* Set line height */
}

#Span_Parraphal_More_Total_Construction{
    font-size: 1.5rem; /* Set font size */
    font-weight: bold; /* Set font weight to bold */
    color: #DB692F; /* Set text color to orange */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    text-align: center; /* Align text to center */
    justify-content: center; /* Justify content */
    display: flex; /* Set display to flex */
    align-items: center; /* Align items to center */
    flex-direction: column; /* Set flex direction to column */
    margin-top: 10px; /* Set top margin */
}



/*--------------------VIEW 2-----------------------------------*!*/

/* Styling for the container within View2 in the carousel */
#Container_View2_Login_Carousel {
    overflow-y: auto;
    display: flex; /* Set display to flex */
    position: fixed; /* Set position to fixed */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    height: 100%; /* Set height to 100% */
    width: 100%; /* Set width to 100% */
    background: transparent; /* Set background to transparent */
    flex-direction: column; /* Set flex direction to column */
}

/* Styling for the first image within View2 in the carousel */
#Image1_View2_Login_Carousel {
    position: relative; /* Set position to relative */
    width: 100%; /* Set width to 100% */
}

/* Styling for the title within View2 in the carousel */
#Tittle_More_View2_Carousel_Login {
    color: white; /* Set text color to white */
    font-size: 2.5rem; /* Set font size */
    font-family: "lato", sans-serif; /* Set font family */
    font-weight: bold; /* Set font weight to bold */
    padding: 0; /* Remove padding */
    text-align: center; /* Align text to center */
    margin: 2% 23% 0 0; /* Set margins */
}

/* Styling for the paragraph within View2 in the carousel */
#Parraphal_More_View2_Carousel_Login {
    display: flex;
    color: white; /* Set text color to white */
    font-size: 1.5rem; /* Set font size */
    font-family: "Arial", sans-serif; /* Set font family */
    font-weight: bold; /* Set font weight to bold */
    padding: 0; /* Remove padding */
    margin: 2% 23% 0 0; /* Set margins */
    cursor: pointer; /* Set cursor to pointer */
    text-align: justify; /* Align text to justify */
}

/* Hover effect for the paragraph within View2 in the carousel */
#Parraphal_More_View2_Carousel_Login:hover {
    color: #DB692F; /* Set text color to orange */
}

/* Styling for the second image within View2 in the carousel */
#Div_More_Total_Construction_View2 {
    padding: 5% 2%; /* Set padding */
    background-color: white; /* Set background color to white */
    height: 50%;  /* Set height to 50% */
    width: 50%; /* Set width to 50% */
    margin: 5% 30% 10% 10%; /* Set margins */
    position: relative; /* Set position to relative */
    z-index: 3; /* Set Z-index to 3 */
    display: flex; /* Set display to flex */
    flex-direction: column; /* Set flex direction to column */
    justify-content: center; /* Center contents vertically */
    align-items: center; /* Center contents horizontally */
    border-radius: 20px;/* Add border radius */
    box-shadow: 1px 1px 5px rgba(234, 105, 38, 0.7); /* Add box shadow */
    text-align: justify; /* Align text to justify */
}

/* Styling for the button within View2 in the carousel */
#Button_More_View2_Carousel_Login {
    display: flex; /* Set display to flex */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    object-fit: cover; /* Fit the content to the container */
    background-color: #DB692F; /* Set background color */
    color: white; /* Set text color to white */
    font-size: 1.5rem; /* Set font size */
    margin-top: 20px; /* Set top margin */
    border: none; /* Remove border */
    width: 25%; /* Set width to 25% */
    height: 60px; /* Set height to 60px */
    cursor: pointer; /* Set cursor to pointer */
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5); /* Add box shadow */
    z-index: 3; /* Set Z-index to 3 */
}


#Parraphal_More_Total_Construction_View2 {
    font-family: Arial, sans-serif; /* Set font family */
    font-size: 16px; /* Set font size */
    line-height: 1.6; /* Set line height */
}

#Parraphal_More_Total_Construction_View2 ul {
    padding-left: 20px;
    /* Indentation for the list */
    margin: 20px 0;
    /* Space above and below the list */
}

#Parraphal_More_Total_Construction_View2 li {
    margin-bottom: 10px;
    /* Space between list items */
    font-weight: bold;

}

#Parraphal_More_Total_Construction_View2 li:before {
    content: "✔️";
    /* Custom bullet icon */
    color: green;
    /* Bullet icon color */
    margin-right: 10px;
    /* Space between bullet and text */
    

}








/*----------------VIEW 3-----------------------------------*/
/* Styling for the container within View3 in the carousel */
#Container_View3_Login_Carousel {
    display: flex; /* Set display to flex */
    position: fixed; /* Set position to fixed */
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    height: 100%; /* Set height to 100% */
    width: 100%; /* Set width to 100% */
}

/* Styling for the left div within View3 in the carousel */
#Div_Left_View3_Carousel_Login {
    right: 60%; /* Set right offset to 70% */
    height: 100%; /* Set height to 100% */
    width: 40%; /* Set width to 30% */
    margin: 0; /* Remove margin */
    padding: 0; /* Remove padding */
    position: absolute; /* Set position to absolute */
}


 /* Styling for the section within View3 in the carousel */
 #Section_More_View3_Carousel_Login {
     position: relative;
     height: 100%;
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     padding: 0;
     margin: 0;
     left: 1%;
     top: 25%;
 }

 /* Styling for the title within View3 in the carousel */
 #Tittle_Services_View3_Carousel_Login {
     color: white;
     font-size: 2.5rem;
     font-family: "Lato", sans-serif;
     font-weight: bold;
     margin: 0;
     padding: 0;
     text-align: center;
 }

 /* Styling for the paragraph within View3 in the carousel */
 #Parraphal_Services_View3_Carousel_Login {
     color: white;
     font-size: 1.2rem;
     font-family: "Lato", sans-serif;
     font-weight: bold;
     margin: 0;
     padding: 0;
     text-align: center;
 }

 /* Styling for the button within View3 in the carousel */
 #Button_More_View3_Carousel_Login {
     color: white;
     font-size: 1.5rem;
     padding: 10px;
     margin-top: 20px;
     border: none;
     width: 25%;
     height: 60px;
     cursor: pointer;
     box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
     background-color: #DB692F;
     transition: transform 0.2s ease;
     z-index: 3;
 }

 /* Hover effect for the button within View3 in the carousel */
 #Button_More_View3_Carousel_Login:hover {
     transform: scale(1.1);
 }

 /* Styling for the additional information container within View3 in the carousel */
 #Div_More_Total_Construction_View3 {
     padding: 2%;
     right: 10%;
     background-color: white;
     height: 80%;
     width: 80%;
     bottom: 40%;
     position: absolute;
     z-index: 3;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     border-radius: 20px;
     box-shadow: 1px 1px 5px rgba(234, 105, 38, 0.7);
 }

 /* Styling for the paragraph within the additional information container */
 #Parraphal_More_Total_Construction_View3 {
     font-size: 1rem;
     font-family: 'Poppins', serif;
     margin: 0;
     padding: 0;
     text-align: justify;
 }

 /* Styling for the unordered lists within the additional information container */
 #Parraphal_More_Total_Construction_View3 ul {
     padding-left: 20px;
     margin: 10px 0;
 }

 /* Styling for the list items within the additional information container */
 #Parraphal_More_Total_Construction_View3 li {
     margin-bottom: 10px;
     padding-left: 10px;
     list-style-type: none;
     font-weight: bold;
     position: relative;
 }

 /* Custom bullet style for the list items */
 #Parraphal_More_Total_Construction_View3 li:before {
     content: "✔️";
     color: green;
     position: absolute;
     left: -20px;
     top: 0;
 }

 /* Styling for the button within the additional information container when moreTotalConstruction is true */
 #Button_More_View3_Carousel_Login.Dropdown {
     display: none;
 }



/* Styling for the right div container within View3 in the carousel */
#Div_Right_View3_Carousel_Login {
    left: 30%;
    /* Set left offset to 30% */
    height: 100%;
    /* Set height to 100% */
    width: 100%;
    /* Set width to 100% */
    margin: 0;
    /* Remove margin */
    padding: 0;
    /* Remove padding */
    position: fixed;
    /* Set position to absolute */
}

/* Styling for the first image within View3 in the carousel */
#Image1_View3_Login_Carousel {
    display: flex;
    /* Uses flexbox to center vertically */
    position: absolute;
    /* Sets position to fixed */
    z-index: 1;
    /* Sets the Z-index for stacking */
    left: 40%;
    top: 5%;
}

/* Styling for the image within the first image container */
#Image1_View3_Login_Carousel img {
    width: 35%;
    /* Adjusts width to 25% of the viewport width */
    height: 25%;
    /* Adjusts height to 25% of the container height */
    object-fit: cover;
    /* Fits the image to the container */
    border-radius: 30px;
    /* Applies border radius */
}

/* Styling for the second image container within View3 in the carousel */
#Image2_View3_Login_Carousel {
    position: relative;
    display: flex;
    /* Uses flexbox to center vertically */
    overflow: hidden;
    /* Hides overflow content */
    z-index: 2;
    /* Sets the Z-index for stacking */
    left: 30%;
    top: 25%;
}

/* Styling for the image within the second image container */
#Image2_View3_Login_Carousel img {
    width: 20%;
    /* Adjusts width to 25% of the viewport width */
    height: 20%;
    /* Adjusts height to 25% of the container height */
    object-fit: cover;
    /* Fits the image to the container */
    border-radius: 30px;
    /* Applies border radius */
}

#Parraphal_More_Total_Construction_View3 h5{
    font-weight: bold !important ;
}


#Parraphal_More_Total_Construction_View3 span{
    font-size: 1.2rem;
        /* Set font size */
        font-weight: bold;
        /* Set font weight to bold */
        color: #DB692F;
        /* Set text color to orange */
        margin: 0;
        /* Remove margin */
        padding: 0;
        /* Remove padding */
        text-align: justify;
        /* Align text to center */
        justify-content: justify;
        /* Justify content */
        display: flex;
        /* Set display to flex */
        align-items: justify;
        /* Align items to center */
        flex-direction: column;
        /* Set flex direction to column */
        margin-top: 10px;
        /* Set top margin */
}

#Parraphal_Services_View3_Carousel_Login{
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: lighter;
    color: white;
    text-align: justify;
    margin: 0;
    padding: 0;
    justify-content: justify;
    display: flex;
    align-items: justify;
    flex-direction: column;
    margin-top: 10px;

}




/* Media queries for responsive design */
@media (max-width: 1420px) {
    #Div_More_Total_Construction{
        width: 100%;
        right: 15%;
        height: 40%;
        bottom: 30%;
    }

    /* Adjust position of a section within the carousel */
    #Section_More_View1_Carousel_Login {
        top: 45%;
        right: 25%;
    }

    /* Adjust width and height of images within the carousel */
    #Image2_View1_Login_Carousel img,
    #Image1_View1_Login_Carousel img {
        width: 55%;
        height: 25%;
    }

    /* Adjust font size of a paragraph within the carousel */
    #Parraphal_More_View2_Carousel_Login {
        font-size: 1.1rem;
    }

    /* Adjust font size of a title within the carousel */
    #Tittle_More_View2_Carousel_Login {
        font-size: 2.2rem;
    }

    /* Adjust position of titles and paragraphs within the carousel */
    #Tittle_More_View2_Carousel_Login,
    #Parraphal_More_View2_Carousel_Login {
        right: 50%;
    }

    /* Hide an image within the carousel */
    #Image1_View3_Login_Carousel img {
        display: none;
    }

    /* Adjust position and size of an image within the carousel */
    #Image2_View3_Login_Carousel img {
        top: 10%;
        width: 40%;
        height: 25%;
    }
}

@media (max-width: 1200px) {
    #Div_More_Total_Construction_View2{
        width: 40%;
        overflow-y: scroll;
    }
    #Div_More_Total_Construction{
        width: 80%;
        right: 8%;
        height: 40%;
        bottom: 40%;
    }

    /* Adjust position of a section within the carousel */
    #Section_More_View1_Carousel_Login {
        top: 50%;
        right: 30%;
    }

    /* Adjust width and height of images within the carousel */
    #Image2_View1_Login_Carousel img,
    #Image1_View1_Login_Carousel img {
        width: 65%;
        height: 25%;
    }

    /* Adjust font size of a paragraph within the carousel */
    #Parraphal_More_View2_Carousel_Login {
        font-size: 1.1rem;
    }

    /* Adjust font size of a title within the carousel */
    #Tittle_More_View2_Carousel_Login {
        font-size: 2.0rem;
    }

    /* Adjust margin and padding of titles and paragraphs within the carousel */
    #Tittle_More_View2_Carousel_Login,
    #Parraphal_More_View2_Carousel_Login {
        margin-right: 40%;
        padding: 20px;
    }
}

/* Media query for screen widths up to 900px */
@media (max-width: 900px) {
    #Div_More_Total_Construction_View2{
        width: 40%;
        margin: 10% 45% 10% 0;
        overflow-y: scroll;
    }
    #Div_More_Total_Construction{
        width: 60%;
        right: 20%;
        height: 40%;
        bottom: 30%;
    }
    /* Adjust position, right padding, and top margin of a section within the carousel */
    #Section_More_View1_Carousel_Login {
        top: 35%;
        right: 25%;
        padding: 10%;
    }

    /* Adjust width and height of images within the carousel */
    #Image2_View1_Login_Carousel img,
    #Image1_View1_Login_Carousel img {
        width: 55%;
        height: 25%;
    }

    /* Adjust top position of an image container within the carousel */
    #Image2_View1_Login_Carousel {
        top: 10%;
    }

    /* Adjust font size and right margin of a paragraph within the carousel */
    #Parraphal_More_View2_Carousel_Login {
        font-size: 1.0rem;
        margin-right: 50%;
    }

    /* Adjust font size and right margin of a title within the carousel */
    #Tittle_More_View2_Carousel_Login {
        font-size: 1.8rem;
        margin-right: 50%;
    }
}

/* Media query for screen widths up to 760px */
@media (max-width: 760px) {
    /* Hide a div */
    #Div_Right_Login {
        display: none;
    }

    /* Adjust width of a div */
    #Div_Left_Login {
        position: fixed;
        width: 100vw;
        display: flex;
        left: 0;
    }
}


