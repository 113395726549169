.Navbar_Component {
    display: flex;
    height: 100%;
    width: 20vw;
    min-width: 300px;
    min-height: 100vh;
    max-width: 450px;
    background-color: #2A364E;
    position: relative;
    z-index: 100;
    flex-direction: column;
}


.Logo_Total_Navbar_Component {
    display: flex;
    justify-content: center;
    height: 90px;
    width: 90%;
    background-color: #ecebeb;
    margin: 5%;
    align-items: center;
    border-radius: 10px;
}


.Image_Logo_Total_Navbar_Component {
    background: url("../Statics/Img/Logototal.png") center no-repeat transparent;
    height: 100%;
    width: 100%;
    margin: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.nav-item {
    margin: 10px;
}

.Exit_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 90%;
    background-color: #ffffff;
    margin: 5%;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5em;
}

#Search_Navbar {
    margin: 15px;
}

#Button_Navbar_Component_Search {
    background-color: #ff4f17;
    color: white;
    border: none;
}

#Button_Navbar_Component_Search:hover {
    background-color: #f36926;
}

#Div_Library_Navbar_Left {
    color: white;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 40px;
    z-index: 3;
    border: transparent;
}

.button {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 4em;
    height: 4em;
    border: none;
    left: 82%;
    background: #2a364e;
    transition: background 0.5s;
}

.button:hover {
    background-color: #ff4f17;
}

.button:active {
    background-color: rgb(130, 0, 0);
}

.button:hover > .close {
    animation: close 0.2s forwards 0.25s;
}

@keyframes close {
    100% {
        opacity: 1;
    }
}
