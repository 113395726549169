/*!* Styling for the body *!*/
/*.Body-Home {*/
/*    display: flex; !* Display as flex container *!*/
/*    position: absolute; !* Position absolute *!*/
/*    height: 100vh; !* Full height of the viewport *!*/
/*    width: 100%; !* Full width *!*/
/*    background-color: #ecebeb; !* Background color *!*/
/*    padding: 0; !* Remove default padding *!*/
/*    margin: 0; !* Remove default margin *!*/
/*    flex-direction: row; !* Flex direction *!*/
/*    flex-wrap: wrap;*/
/*}*/

/*!* Navbar AdminVideos Top *!*/
/*#Navbar_Admin_Top {*/
/*    position: fixed;*/
/*    width: 100vw;*/
/*    height: 10vh;*/
/*    left: 5%;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-flow: row;*/
/*    justify-content: space-between;*/
/*    z-index: 2;*/

/*}*/

/*#Div_Navbar_Top_Admin_Widgets_Notifications {*/
/*    margin: 2%;*/
/*    position: relative;*/
/*}*/


/*#Section_Navbar_Top_Admin_Profile {*/
/*    height: 100%;*/
/*    width: 20%;*/
/*    right: 5vw;*/
/*    top: 20%;*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*}*/

/*#Div_Navbar_Top_Admin_Profile_Icon {*/
/*    height: 60%;*/
/*    width: 60px;*/
/*    border-radius: 10pc;*/
/*    margin: 1% 2%;*/
/*    background: url("../Statics/Img/IMG_Example_Profile.jpg") no-repeat center center / cover;*/
/*}*/

/*#Div_Navbar_Top_Admin_Profile_Info {*/
/*    right: 5%;*/
/*    display: flow;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    margin: 1%;*/
/*}*/


/*!* Styling for the Navbar *!*/
/*.Navbar {*/
/*    background-color: transparent; !* Background color *!*/
/*    height: 15%; !* Height of the navbar *!*/
/*    width: 100%; !* Full width *!*/
/*    display: flex; !* Display as flex container *!*/
/*    justify-content: normal; !* Space between elements *!*/
/*    padding: 0; !* Remove default padding *!*/
/*    margin: 0; !* Remove default margin *!*/
/*    flex-direction: column; !* Flex direction *!*/
/*    position: static; !* Position relative *!*/
/*}*/

/*!* Styling for the navbar container *!*/
/*.Container-Navbar-Fluid {*/
/*    width: 100%; !* Full width *!*/
/*    display: flex; !* Display as flex container *!*/
/*    padding: 0; !* Remove default padding *!*/
/*    margin: 0; !* Remove default margin *!*/
/*    height: 100%; !* Full height *!*/
/*    justify-content: space-between;*/
/*    flex-direction: row; !* Flex direction *!*/
/*}*/

/*!* Styling for the navbar toggler button *!*/
/*.Button-Navbar-Toggler {*/
/*    background-color: #2A364E; !* Background color *!*/
/*    height: 50px; !* Full height *!*/
/*    width: 60px; !* Width *!*/
/*    display: flex; !* Display as flex container *!*/
/*    justify-content: center; !* Center content horizontally *!*/
/*    align-items: center; !* Center content vertically *!*/
/*    margin: 3vh; !* Margin *!*/
/*    border-radius: 18%; !* Border radius *!*/
/*}*/


/*!* Styling for the Navbar logo *!*/
/*.Navbar-Logo {*/
/*    background-image: url("../Statics/Img/Logototal.png"); !* Background image *!*/
/*    background-position: center; !* Center the background image *!*/
/*    background-size: 40%; !* Size of the background image *!*/
/*    height: 10vh; !* Full height *!*/
/*    width: 30%; !* Width of the logo *!*/
/*    display: flex; !* Display as flex container *!*/
/*    background-repeat: no-repeat; !* Do not repeat the background image *!*/
/*    position: absolute; !* Position relative *!*/
/*    margin-left: 70vw;*/
/*}*/

/*!* Styling for the Navbar logo when in dropdown mode *!*/
/*.Navbar-Logo.Dropdown {*/
/*    display: none; !* Hide the logo in dropdown mode *!*/
/*}*/

/*!* Styling for the container of the navbar in dropdown mode *!*/
/*.Container-Navbar-Fluid.Dropdown {*/
/*    background-color: #2A364E; !* Background color *!*/
/*    height: 100vh; !* Full height of the viewport *!*/
/*    width: 22vh; !* Width *!*/
/*    box-shadow: 2px 2px 2px rgba(93, 93, 93, 0.93); !* Box shadow *!*/
/*    z-index: 99; !* Set z-index *!*/
/*    position: relative; !* Position relative *!*/
/*    display: flex; !* Display as flex container *!*/
/*    flex-direction: column; !* Flex direction *!*/
/*    min-height: 100vh; !* Minimum height *!*/
/*    min-width: 22vh; !* Minimum width *!*/

/*}*/

/*!* Styling for the left menu items in dropdown mode *!*/
/*.Menu-Dropdown-Left {*/
/*    font-weight: normal; !* Normal font weight *!*/
/*    font-family: "lato", serif; !* Font family *!*/
/*    font-size: 1em; !* Font size *!*/
/*    color: white; !* Text color *!*/
/*    padding: 0; !* Padding *!*/
/*    z-index: 2; !* Set z-index *!*/
/*    position: relative; !* Position absolute *!*/
/*    justify-content: flex-start; !* Center content horizontally *!*/
/*    align-content: center; !* Center content vertically *!*/
/*    display: flex; !* Display as flex container *!*/
/*    flex-direction: column; !* Flex direction *!*/
/*}*/

/*.Button-Navbar-Toggler.Dropdown {*/
/*    background-color: #2A364E; !* Background color *!*/
/*    height: 50px; !* Full height *!*/
/*    width: 60px; !* Width *!*/
/*    display: flex; !* Display as flex container *!*/
/*    position: absolute; !* Position absolute *!*/
/*    justify-content: center; !* Center content horizontally *!*/
/*    align-items: center; !* Center content vertically *!*/
/*    border-radius: 38%; !* Border radius *!*/
/*    left: 90%; !* Margin left *!*/
/*}*/

/*!* Styling for the right menu items in dropdown mode *!*/
/*.Navbar_Component {*/
/*    position: absolute; !* Position absolute *!*/
/*}*/


/*!* Styling for the home menu item in dropdown mode *!*/
/*.Home-Dropdown {*/
/*    display: flex; !* Display as flex container *!*/
/*    position: relative; !* Position relative *!*/
/*    margin-bottom: 10vh; !* Margin bottom *!*/
/*    margin-top: 5vh; !* Margin top *!*/
/*    align-items: center;*/
/*}*/

/*.Profile-Dropdown, .About-Dropdown, .Contact-Dropdown {*/
/*    background-color: transparent; !* Transparent background *!*/
/*    display: flex; !* Display as flex container *!*/
/*    position: relative; !* Position relative *!*/
/*    justify-content: normal; !* Justify content *!*/
/*    margin-bottom: 10vh; !* Margin bottom *!*/

/*}*/


/*!* Styling for the hovered menu items in dropdown mode *!*/
/*.Home-Dropdown:hover,*/
/*.Profile-Dropdown:hover,*/
/*.About-Dropdown:hover,*/
/*.Contact-Dropdown:hover,*/
/*.Exit-Dropdown:hover {*/
/*    background-color: rgba(0, 0, 0, 0.55); !* Semi-transparent background color *!*/
/*    color: white; !* Text color *!*/
/*    border-radius: 10px; !* Border radius *!*/
/*    width: 100%; !* Width *!*/
/*}*/


/*!* Styling for the main content body *!*/
/*.Body-Content {*/
/*    background: #ecebeb center no-repeat; !* Background *!*/
/*    display: flex; !* Display as flex container *!*/
/*    justify-content: center; !* Justify content *!*/
/*    align-items: center; !* Align items *!*/
/*    width: 100%; !* Full width *!*/
/*    height: 100%; !* Full height *!*/
/*    position: fixed; !* Position static *!*/
/*    top: 15%; !* Top *!*/
/*}*/


/* Styling for the left div */
.Div-left {
    height:73.5%; /* Full height */
    width: 30%; /* Width */
    background-color: #1A283F; /* Background color */
    margin: 7% -9% 0 6%;
    border-radius: 10px; /* Border radius */
    position: relative; /* Position absolute */
}

/* Styling for the search */
.search {
    margin: 2% 0 0 0; /* Margin */
    padding: 0; /* Padding */
}

/* Styling for the secondary outline button */
.btn.btn-outline-secondary {
    color: #ececec; /* Text color */
    background-color: #ff4f17; /* Background color */
    border-color: #ff4f17; /* Border color */
}

/* Styling for the description */
.Description {
    margin: 5% 3% 2% 3%; /* Margin */
    padding: 2%; /* Padding */
    background-color: #ececec; /* Background color */
    border-radius: 10px; /* Border radius */
    box-shadow: 2px 2px 2px 2px rgba(93, 93, 93, 0.93); /* Box shadow */
    height: 85%; /* Height */
    position: relative; /* Position */
    flex-direction: column; /* Flex direction */
}

/* Styling for the title within the description */
.Tittle-Description {
    justify-content: center; /* Justify content */
    display: flex; /* Display as flex container */
    position: static; /* Position static */
    color: rgb(4, 12, 75); /* Text color */
    font-family: "lato", inherit, serif; /* Font family */
    padding: 0; /* Padding */
    margin: 0 0 5% 0; /* Margin */
    font-weight: bold;
}

/* Styling for the paragraph within the description */
.Parraphal-Description {
    padding: 5%;
    text-align: justify; /* Text alignment */
    justify-content: center; /* Justify content */
    align-items: center; /* Align items */
    color: black; /* Text color */
    font-size: 18px; /* Font size */
    line-height: 1.5; /* Line height */
    margin-bottom: 50px; /* Margin bottom */
    overflow: scroll; /* Overflow scroll */
    height: 85%; /* Height */
}

/* Styling for the SVG */
svg {
    margin-left: 0; /* Margin left */
    justify-content: normal; /* Justify content */
    align-items: center; /* Align items */
}

/*!* Styling for the right div *!*/
/*.Div-Right {*/
/*    flex-direction: column; !* Flex direction *!*/
/*    display: flex; !* Display as flex container *!*/
/*    position: absolute; !* Position absolute *!*/
/*    left: 48%; !* Left *!*/
/*    height: 85%; !* Full height *!*/
/*    width: 50%; !* Width *!*/
/*    background-color: #2A364E; !* Background color *!*/
/*    padding: 1%; !* Padding *!*/
/*    border-radius: 10px; !* Border radius *!*/
/*    justify-content: center; !* Center content horizontally *!*/
/*    align-items: center; !* Center content vertically *!*/
/*    bottom: 20%; !* Bottom *!*/
/*}*/

/*#Div_Right_Intro{*/
/*    width: 80%;*/
/*    position: absolute;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    left: 12%;*/

/*}*/

/*!* Styling for the intro button *!*/
/*.Button-Intro {*/
/*    display: flex; !* Display as flex container *!*/
/*    justify-content: center; !* Center horizontally *!*/
/*    align-items: center; !* Center vertically *!*/
/*    text-align: center; !* Center text horizontally *!*/
/*    position: relative; !* Position relative *!*/
/*    color: white; !* Text color *!*/
/*    font-family: "cormorant garamond light", serif; !* Font family *!*/
/*    font-size: 1.5em; !* Font size *!*/
/*    z-index: 9; !* Set z-index *!*/
/*    background-color: #ff4f17; !* Background color *!*/
/*    border: none; !* No border *!*/
/*    border-radius: 10px; !* Border radius *!*/
/*    height: 8vh; !* Height *!*/
/*    width: 25vh; !* Width *!*/
/*    box-shadow: 2px 2px 2px rgba(93, 93, 93, 0.93); !* Box shadow *!*/
/*    margin: 5% 0 0 0; !* Margin *!*/
/*}*/

/*!* Style for the video card container *!*/
/*.Card-Video {*/
/*    !* Position the card absolutely within its containing element *!*/
/*    position: absolute;*/

/*    !* Make the card a flex container *!*/
/*    display: flex;*/

/*    !* Center its children horizontally and vertically *!*/
/*    justify-content: center;*/
/*    align-items: center;*/

/*    !* Set the height to 110 viewport heights and width to 100% *!*/
/*    height: 110vh;*/
/*    width: 100%;*/

/*    !* Background color with transparency *!*/
/*    background-color: rgba(0, 0, 0, 0.47);*/

/*    !* Set the stacking order of the card *!*/
/*    z-index: 9;*/

/*    !* Margin to create some space between this card and the next element *!*/
/*    margin-bottom: 15%;*/
/*}*/

/*!* Style for the introductory text container *!*/
/*.Card-Intro-Text {*/
/*    !* Position the text container absolutely within its containing element *!*/
/*    position: absolute;*/

/*    !* Display the container as a flexbox *!*/
/*    display: flex;*/

/*    !* Center its children horizontally and vertically *!*/
/*    justify-content: center;*/
/*    align-items: center;*/

/*    !* Set the height to 100 viewport heights and width to 100% *!*/
/*    height: 100vh;*/
/*    width: 100%;*/

/*    !* Background color with transparency *!*/
/*    background-color: rgba(0, 0, 0, 0.42);*/

/*    !* Set the stacking order of the text container *!*/
/*    z-index: 9;*/

/*    !* Margin to create some space between this container and the next element *!*/
/*    margin-bottom: 15%;*/

/*    !* Arrange flex items in a column direction *!*/
/*    flex-direction: column;*/
/*}*/


/*@media (max-width: 800px) {*/
/*    .Div-left {*/
/*        width: 100%; !* Full width *!*/
/*        right: 0; !* Right *!*/
/*        bottom: 0; !* Bottom *!*/
/*    }*/

/*    .Div-Right {*/
/*        width: 100%; !* Full width *!*/
/*        left: 0; !* Left *!*/
/*        bottom: 0; !* Bottom *!*/
/*    }*/

/*    .Button-Intro {*/
/*        width: 100%; !* Full width *!*/
/*    }*/

/*    .Button-Navbar-Toggler.Dropdown {*/

/*        left: 80%; !* Margin left *!*/
/*    }*/
/*}*/

/*!* Media query for screens with a maximum width of 1300px *!*/
/*@media (max-width: 1300px) {*/
/*    !* Adjust the background size of the Navbar Logo *!*/
/*    .Navbar-Logo {*/
/*        background-size: 50%; !* Background size *!*/
/*    }*/
/*    .Div-left{*/
/*        left: 7%; !* Left *!*/
/*    }*/

/*    !* Adjust the height and position of Div-left and Div-Right *!*/
/*    .Div-left, .Div-Right {*/
/*        height: 70%; !* Height *!*/
/*        bottom: 30%; !* Bottom *!*/
/*    }*/
/*}*/

/*!* Media query for screens with a maximum width of 1000px *!*/
/*@media (max-width: 1000px) {*/
/*    !* Adjust the background size of the Navbar Logo *!*/
/*    .Navbar-Logo {*/
/*        background-size: 60%; !* Background size *!*/
/*    }*/
/*    !* Adjust the height and position of Div-left and Div-Right *!*/
/*    .Div-left, .Div-Right {*/
/*        height: 50%; !* Height *!*/
/*        bottom: 40%; !* Bottom *!*/
/*    }*/
/*    .Div-left{*/
/*        left: 7%; !* Left *!*/
/*    }*/
/*}*/

/*!* Media query for screens with a maximum width of 800px *!*/
/*@media (max-width: 800px) {*/
/*    !* Flexbox styling for the Body-Content *!*/
/*    .Body-Content {*/
/*        display: flex; !* Display as flex container *!*/
/*        flex-direction: column; !* Flex direction *!*/
/*        justify-content: center; !* Center content horizontally *!*/
/*        align-items: center; !* Center content vertically *!*/
/*        height: 100vh; !* Full height *!*/
/*        overflow: hidden; !* Overflow hidden *!*/
/*    }*/

/*    !* Styling for the Div-left container *!*/
/*    .Div-left {*/
/*        width: 100%; !* Full width *!*/
/*        height: 50vh; !* Height *!*/
/*        overflow: hidden; !* Overflow hidden *!*/
/*        border-bottom: 1px solid #ccc; !* Border *!*/
/*    }*/

/*    !* Styling for the Div-Right container *!*/
/*    .Div-Right {*/
/*        width: 100%; !* Full width *!*/
/*        height: 50vh; !* Height *!*/
/*        overflow: hidden; !* Overflow hidden *!*/
/*        justify-content: center; !* Center content horizontally *!*/
/*        align-items: center; !* Center content vertically *!*/
/*    }*/

/*    !* Styling for the Description-Container *!*/
/*    .Description-Container {*/
/*        padding: 20px; !* Padding *!*/
/*        text-align: center; !* Text alignment *!*/
/*        color: #333; !* Text color *!*/
/*        font-size: 16px; !* Font size *!*/
/*    }*/

/*    !* Styling for the Video-Container *!*/
/*    .Video-Container {*/
/*        width: 100%; !* Full width *!*/
/*        height: 100%; !* Full height *!*/
/*        position: relative; !* Position relative *!*/
/*    }*/

/*    !* Styling for the Video-Player *!*/
/*    .Video-Player {*/
/*        width: 100%; !* Full width *!*/
/*        height: 100%; !* Full height *!*/
/*    }*/

/*    !* Additional styling for iframes *!*/
/*    iframe {*/
/*        justify-content: center; !* Center content horizontally *!*/
/*        align-items: center; !* Center content vertically *!*/
/*        content: normal; !* Content *!*/
/*        display: flex; !* Display as flex container *!*/
/*    }*/
/*}*/


#Body_Home_Component {
    position: fixed;
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
}

#Section_Video_Home {
    position: relative;
    height: 100%;
    width: 95%;
    left: 5%;

    display: flex;
    flex-direction: column;
}

#Div_Title_Video {
    position: relative;
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#Div_Video_Home {
    position: relative;
    padding: 1% 10%;
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#Tittle_Video_Home {
    position: relative;
    font-size: 2em;
    font-family: "lato", serif;
    display: flex;
    text-align: center;
    justify-content: center;
    color: #2a364e;
    margin: 0 0 2% 0 ;

}


.video-rounded {
    border-radius: 15px; /* Ajusta el valor según tus necesidades */
    overflow: hidden; /* Para asegurarte de que el contenido dentro del borde redondeado no se desborde */
}




#Section_Navbar_Top_Admin_Profile_Home {
    height: 100%;
    width: 20%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0;
    right: 0;
    top: 0;
    left: 46%;
    background-Color: transparent;
}

#Div_Navbar_Top_Admin_Profile_Icon_Home {
    height: 60%;
    width: 60px;
    border-radius: 10pc;
    margin: 1% 2%;
    background: url("../Statics/Img/IMG_Example_Profile.jpg") no-repeat center center / cover;
}

#Div_Navbar_Top_Admin_Profile_Info_Home {
    right: 5%;
    display: flow;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1%;
}